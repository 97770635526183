<script setup lang="ts">
interface Props {
  modelValue?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false
})

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  'close': []
}>()

const email = ref('')
const firstName = ref('')
const lastName = ref('')
const consent = ref(false)
const loading = ref(false)
const toast = useToast()

const isValidEmail = computed(() =>
  email.value === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)
)

async function handleSubmit() {
  loading.value = true
  try {
    await $fetch('/api/notify', {
      method: 'POST',
      body: {
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        consent: consent.value
      }
    })
    toast.add({ title: 'Merci', description: 'Vous serez tenu au courant des actualités de MaMut', color: 'green' })
    emit('update:modelValue', false)
  } catch {
    toast.add({ title: 'Erreur', description: 'Une erreur est survenue', color: 'red' })
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <UModal :model-value="modelValue" @update:model-value="(val) => emit('update:modelValue', val)">
    <UCard>
      <template #header>
        <div class="text-center space-y-1">
          <UIcon name="heroicons:bell" class="hidden md:block h-8 w-8 text-primary-500 mx-auto mb-2" />
          <h3 class="text-lg font-medium">Soyez informé du lancement</h3>
        </div>
      </template>

      <form @submit.prevent="handleSubmit" class="space-y-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <UFormGroup label="Prénom">
            <UInput v-model="firstName" placeholder="Votre prénom" required icon="heroicons:user"
              :ui="{ input: 'text-base' }" />
          </UFormGroup>

          <UFormGroup label="Nom">
            <UInput v-model="lastName" placeholder="Votre nom" required icon="heroicons:user"
              :ui="{ input: 'text-base' }" />
          </UFormGroup>
        </div>

        <UFormGroup label="Email" :error="!isValidEmail ? 'Email invalide' : undefined">
          <UInput v-model="email" type="email" placeholder="votre@email.com" required icon="heroicons:envelope"
            :color="!isValidEmail ? 'red' : undefined" class="text-base" />
        </UFormGroup>

        <UFormGroup>
          <UCheckbox v-model="consent" required class="text-sm"
            label="J'accepte de recevoir des notifications concernant le lancement et les actualités du service." />

        </UFormGroup>

        <div class="flex gap-2 justify-end">
          <UButton color="gray" variant="ghost" @click="emit('update:modelValue', false)">
            Annuler
          </UButton>
          <UButton type="submit" :loading="loading" :disabled="!consent || !isValidEmail"
            icon="heroicons:paper-airplane">
            M'informer
          </UButton>
        </div>
      </form>

      <template #footer>
        <p class="text-xs text-gray-500 text-center">
          Vos données sont protégées et ne seront jamais partagées
        </p>
      </template>
    </UCard>
  </UModal>
</template>
