<template>
    <div v-if="content" class="sr-only" aria-hidden="true">
        <h2>{{ content.title }}</h2>
        <div v-html="content.content"></div>
    </div>
</template>

<script setup lang="ts">
import { useAsyncData, queryContent } from '#imports'

const { data: content } = await useAsyncData('seo-content', () =>
    queryContent('seo-content').findOne()
)
</script>

<style scoped>
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
</style>
