<script setup lang="ts">
const { data: page } = await useAsyncData('index', () => queryContent('/').findOne())
const { showModal, handleRedirect } = useAppRedirect()

// Fonction pour gérer les clics sur les liens
const handleHeroLinkClick = (link: any) => {
  handleRedirect()
}

useSeoMeta({
  title: page.value.title,
  ogTitle: page.value.title,
  description: page.value.description,
  ogDescription: page.value.description
})
</script>

<template>
  <div>
    <SeoContent />
    <ULandingHero :title="page.hero.title" :description="page.hero.description" :links="page.hero.links.map(link => ({
      ...link,
      click: (e) => {
        e.preventDefault()
        handleHeroLinkClick(link)
      }
    }))">
      <template #headline>
        <UBadge v-if="page.hero.headline" variant="subtle" size="lg" class="relative rounded-full font-semibold">
          <NuxtLink :to="page.hero.headline.to" class="focus:outline-none" tabindex="-1">
            <span class="absolute inset-0" aria-hidden="true" />
          </NuxtLink>

          {{ page.hero.headline.label }}

          <UIcon v-if="page.hero.headline.icon" :name="page.hero.headline.icon"
            class="ml-1 w-4 h-4 pointer-events-none" />
        </UBadge>
      </template>

      <!-- <ImagePlaceholder /> -->

      <!-- <ULandingLogos :title="page.logos.title" align="center">
        <UIcon v-for="icon in page.logos.icons" :key="icon" :name="icon"
          class="w-12 h-12 lg:w-16 lg:h-16 flex-shrink-0 text-gray-900 dark:text-white" />
      </ULandingLogos> -->
    </ULandingHero>

    <ULandingSection :title="page.problem.title" :description="page.problem.description"
      :headline="page.problem.headline" class="bg-gray-50 dark:bg-gray-900">
      <ULandingGrid id="problem" class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)]">
        <ULandingCard v-for="(item, index) in page.problem.items" :key="index" v-bind="item"
          class="col-span-6 row-span-3" />
      </ULandingGrid>
    </ULandingSection>

    <ULandingSection :title="page.features.title" :description="page.features.description"
      :headline="page.features.headline">
      <ULandingGrid id="features" class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)]">
        <ULandingCard v-for="(item, index) in page.features.items" :key="index" v-bind="item"
          class="col-span-4 row-span-3" />
      </ULandingGrid>
    </ULandingSection>

    <ULandingSection :title="page.pricing.title" :description="page.pricing.description"
      :headline="page.pricing.headline">
      <UPricingGrid id="pricing" :compact="false" :ui="{
        wrapper: 'grid grid-cols-1 lg:grid-cols-2 w-full justify-center items-center gap-4 sm:gap-8'
      }" class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)] max-w-6xl mx-auto px-4 sm:px-6">
        <UPricingCard v-for="(plan, index) in page.pricing.plans" :key="index" v-bind="plan" :button="{
          ...plan.button,
          click: (e) => {
            e.preventDefault()
            handleHeroLinkClick(plan.button)
          }
        }" class="w-full" />
      </UPricingGrid>
    </ULandingSection>

    <!-- <ULandingSection :headline="page.testimonials.headline" :title="page.testimonials.title"
      :description="page.testimonials.description">
      <UPageColumns id="testimonials" class="xl:columns-4 scroll-mt-[calc(var(--header-height)+140px+128px+96px)]">
        <div v-for="(testimonial, index) in page.testimonials.items" :key="index" class="break-inside-avoid">
          <ULandingTestimonial v-bind="testimonial" />
        </div>
      </UPageColumns>
    </ULandingSection> -->

    <ULandingSection class="bg-primary-50 dark:bg-primary-400 dark:bg-opacity-10">
      <ULandingCTA v-bind="{
        ...page.cta,
        links: page.cta.links.map(link => ({
          ...link,
          click: (e) => {
            e.preventDefault()
            handleHeroLinkClick(link)
          }
        }))
      }" :card="false" />
    </ULandingSection>

    <ULandingSection id="faq" :title="page.faq.title" :description="page.faq.description"
      class="scroll-mt-[var(--header-height)]">
      <ULandingFAQ multiple :items="page.faq.items" :ui="{
        button: {
          label: 'font-semibold',
          trailingIcon: {
            base: 'w-6 h-6'
          }
        }
      }" class="max-w-4xl mx-auto" />
    </ULandingSection>

    <NotifyMeModal v-model="showModal" />
  </div>
</template>
